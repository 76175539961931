const React = require('react')

const APP_ROUTE_UPDATE = 'app.route.update'
const PubSub = require('pubsub-js')
const { FilterDataProvider } = require('./src/context/FilterContext')

const {
  TrackingContainer,
} = require('./src/modules/tracking/TrackingContainer')

exports.onRouteUpdate = ({ location, prevLocation }) => {
  PubSub.publish(APP_ROUTE_UPDATE, {
    location: location.pathname.replace(/\/$/, '') || '/',
    previousLocation: prevLocation ? prevLocation.pathname : null,
  })
}

// Prerender font by putting it in the root of the page
exports.wrapRootElement = ({ element }) => (
  <>
    <TrackingContainer />
    <span
      className="preload-univers-lt-std"
      style={{
        visibility: 'hidden',
        position: 'absolute',
        top: -9999,
        left: -9999,
      }}
    >
      Ohpen
    </span>
    <FilterDataProvider>{element}</FilterDataProvider>
  </>
)

exports.shouldUpdateScroll = () => false
